exports.shouldUpdateScroll = ({routerProps}) => {
  // Don't override hash scrolling
  if (routerProps?.location?.hash) return false;

  setTimeout(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
  }, 100);

  return false;
};
